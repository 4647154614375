import { FlecheLeft, FlecheRight } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';

import Button from '../Buttons';

import styles from './CardCarousel.module.scss';
import { Props } from './CardCarousel.types';

const CardCarousel = (props: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = Math.min(React.Children.count(props.children) - 1, 4);
    } else if (newIndex >= Math.min(React.Children.count(props.children), 5)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  return (
    <div className={classnames(styles.container, props.containerClassName)}>
      <div className={styles.topContainer}>
        <div className={styles.titleContainer}>
          <div className={classnames(styles.title, props.titleClassName)}>{props.title}</div>
          <div className={classnames(styles.dots, props.dotsClassName)}>
            {props.children?.length !== 1 &&
              props.children.map((_child, index) => (
                <>
                  {index < 5 && (
                    <Button.Primary
                      key={index}
                      onClick={() => {
                        setActiveIndex(index);
                      }}
                      className={classnames(styles.bullet, {
                        [styles.notSelected]: activeIndex !== index,
                      })}
                    ></Button.Primary>
                  )}
                </>
              ))}
            {props.children?.length > 5 && (
              <a className={styles.more} href={props.link ?? ''}>
                {props.linkText}
              </a>
            )}
            <p className={styles.filler}> </p>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          {props.children.length > 1 && (
            <>
              <Button.Tertiary
                className={styles.button}
                onClick={() => {
                  updateIndex(activeIndex - 1);
                }}
              >
                <FlecheLeft />
              </Button.Tertiary>
              <Button.Tertiary
                className={styles.button}
                onClick={() => {
                  updateIndex(activeIndex + 1);
                }}
              >
                <FlecheRight />
              </Button.Tertiary>
            </>
          )}
        </div>
      </div>
      <div
        className={styles.carouselContainer}
        style={{
          transform: `translateX(-${activeIndex * 100}%)`,
        }}
      >
        {React.Children.map(props.children, (child, index) => {
          if (index < 5) {
            return React.cloneElement(
              <div className={classnames(styles.children, props.childrenClassName)}>
                <div className={styles.childrenContainer}>{child}</div>
              </div>
            );
          }
          return;
        })}
      </div>
    </div>
  );
};

export default CardCarousel;
