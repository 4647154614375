import styles from './ToolBar.module.scss';
const Theme = {
  ltr: styles.ltr,
  text: {
    bold: styles.bold,
    italic: styles.italic,
    underline: styles.underline,
    strikethrough: styles.uppercase,
  },
  list: {
    listitem: styles.list,
  },
  element: {
    center: styles.list,
  },
};

export default Theme;
