import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import classNames from 'classnames';
import { SerializedEditorState } from 'lexical';
import React, { useEffect, useState } from 'react';

import Theme from '../ToolBar/Theme';

import styles from './FormattedText.module.scss';
import { Props } from './FormattedText.types';

const editorConfig = {
  editable: false,
  namespace: 'MyEditor',
  // The editor theme
  theme: Theme,
  // Handling of errors during update
  onError(error: unknown) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};
const Label = ({ data, border, textcolor }: Props) => {
  const [editor] = useLexicalComposerContext();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isLoading) {
      const editorState = editor.parseEditorState(data as SerializedEditorState);
      editor.setEditorState(editorState);
      setIsLoading(false);
    }
  }, [isLoading, editor, data]);

  return (
    <div
      className={classNames(styles.editor, border ? styles.border : '')}
      style={{ color: textcolor }}
    >
      <RichTextPlugin
        contentEditable={<ContentEditable />}
        placeholder={<div></div>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <HistoryPlugin />
      <AutoFocusPlugin />

      <ListPlugin />
      <LinkPlugin />
    </div>
  );
};
const FormattedText = (args: Props) => {
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Label {...args} />
    </LexicalComposer>
  );
};
export default FormattedText;
