import { Tab, Tabs } from '@mui/material';
import classnames from 'classnames';
import React, { useState } from 'react';

import styles from './SegmentedMenu.module.scss';
import { Props } from './SegmentedMenu.types';

function SegmentedMenu<T>({
  controls,
  selected,
  onSelectionChange,
  keyValueExtractor,
  className,
  leftIconClassName,
  rightIconClassName,
  controlItemClassName,
  bottomBarClassName,
  separatorClassName,
  displayVerticalSeparator,
  verticalSeparatorClassName,
}: Props<T>) {
  const [selectedTab, setSelectedTab] = useState(
    controls.findIndex(item => keyValueExtractor(item).key === selected)
  );
  return (
    <>
      <Tabs
        value={selectedTab}
        className={classnames(styles.tabMenuContainer, className)}
        TabIndicatorProps={{ className: classnames(styles.bottomBar, bottomBarClassName) }}
      >
        {controls.map((control, idx) => {
          const { key, value, leftIcon, rightIcon } = keyValueExtractor(control);
          return (
            <div key={key} className={styles.tabItem}>
              {leftIcon && (
                <div className={classnames(styles.iconContainer, leftIconClassName)}>
                  {leftIcon}
                </div>
              )}
              <Tab
                className={classnames(
                  {
                    [styles.selectedText]: key === selected,
                  },
                  controlItemClassName
                )}
                label={value}
                onClick={() => {
                  onSelectionChange(control);
                  setSelectedTab(idx);
                }}
              />
              {rightIcon && (
                <div className={classnames(styles.iconContainer, rightIconClassName)}>
                  {rightIcon}
                </div>
              )}
              {displayVerticalSeparator && controls.length !== idx + 1 && (
                <div className={classnames(styles.verticalSeparator, verticalSeparatorClassName)} />
              )}
            </div>
          );
        })}
      </Tabs>
      <div className={classnames(styles.horizontalSeparator, separatorClassName)} />
    </>
  );
}

export default SegmentedMenu;
