import { BlackCircleCheck } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classNames from 'classnames';
import React from 'react';

import styles from './SimplifiedHeader.module.scss';
import { Props } from './SimplifiedHeader.types';
const SimplifiedHeader = ({ fields, selectedkey, selected, onSelectionChange }: Props) => {
  return (
    <div className={styles.container}>
      {selectedkey.map((key: string, index: number) => (
        <>
          <div
            className={styles.circleContainer}
            key={`header-${key}`}
            onClick={() => onSelectionChange(key)}
          >
            {key !== selected && <div className={styles.circle}></div>}
            {key === selected && (
              <BlackCircleCheck width={25} height={25} className={styles.selectedcircle} />
            )}
            <div
              className={classNames(
                styles.textContainer,
                key === selected ? styles.selectedtext : styles.text
              )}
            >
              {fields[index]}
            </div>
          </div>
          {index < selectedkey.length - 1 && <div className={styles.bar} key={`bar-${key}`}></div>}
        </>
      ))}
    </div>
  );
};

export default SimplifiedHeader;
