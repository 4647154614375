import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  ListNode,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $findMatchingParent, $getNearestNodeOfType } from '@lexical/utils';
import {
  Bold,
  AlignmentCenter,
  AlignmentLeft,
  AlignmentRight,
  Italic,
  Uppercase,
  ListNumber,
  Underline,
  List,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import Button from '../../Buttons';

import styles from './ToolBar.module.scss';
interface Tool {
  label: string;
  style: string;
  method: string;
  icon?: ReactElement;
  border?: boolean;
  onClick?: any;
  active?: boolean;
}

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
};

const Toolbar = () => {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isUppercase, setIsUppercase] = useState(false);
  const [align, setAlign] = useState('left');

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsUppercase(selection.hasFormat('strikethrough'));
      if (elementDOM !== null) {
        setAlign(elementDOM.style.textAlign || 'left');
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          const type = parentList ? parentList.getListType() : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
        console.log(elementDOM.style.textAlign);
        if (elementDOM.style.textAlign === 'center') {
          console.log('test1');
          // If textAlign is "left", set the marginLeft style inline
          elementDOM.style.marginLeft = '0rem';
        }
      }
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, updateToolbar]);

  const CenterElement = () => {
    const [hover, setHover] = useState(false);
    const element = useMemo(() => {
      const tools: Tool[] = [
        {
          label: 'Gauche',
          style: 'unstyled',
          method: 'block',
          icon: <AlignmentLeft width={40} height={40} className={styles.LogoImage} />,
          onClick: () => {
            setAlign('left');
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
          },
          active: align === 'left',
        },
        {
          label: 'Centrer',
          style: 'CENTER',
          method: 'block',
          icon: <AlignmentCenter width={40} height={40} className={styles.LogoImage} />,
          onClick: () => {
            setAlign('center');
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
          },
          active: align === 'center',
        },
        {
          label: 'Droite',
          style: 'RIGHT',
          method: 'block',
          icon: <AlignmentRight width={40} height={40} className={styles.LogoImage} />,
          border: false,
          onClick: () => {
            setAlign('right');
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
          },
          active: align === 'right',
        },
      ];

      const element = document.getElementById('mousedetection'); // Replace "yourElementId" with the actual ID of your element
      if (element) {
        element.addEventListener('mouseleave', (event: MouseEvent) => {
          const mouseY = event.clientY;
          const bottomThreshold = 10; // Adjust this threshold as needed

          const elementRect = element.getBoundingClientRect();
          const elementBottom = elementRect.bottom;

          if (mouseY < elementBottom - bottomThreshold) {
            setHover(false);
          }
        });
      }

      return (
        <div
          className={styles.centerElement}
          onMouseEnter={() => setHover(true)}
          id={'mousedetection'}
        >
          {hover && (
            <div className={styles.centerElementContainer} onMouseLeave={() => setHover(false)}>
              {tools.map(item => (
                <Button.Tertiary
                  className={ClassNameIcon(item)}
                  key={`${item.label}`}
                  onClick={item.method !== 'none' ? item.onClick : null}
                  onMouseDown={e => e.preventDefault()}
                >
                  {item.icon || item.label}
                </Button.Tertiary>
              ))}
            </div>
          )}
          {align === 'center' ? tools[1].icon : align === 'right' ? tools[2].icon : tools[0].icon}
        </div>
      );
    }, [hover]);
    return element;
  };
  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      setBlockType('bullet');
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      setBlockType('paragraph');
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      setBlockType('number');
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      setBlockType('paragraph');
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const tools: Tool[] = [
    {
      label: 'Majuscule',
      style: 'MAJUSCULE',
      method: 'inline',
      icon: <Uppercase width={40} height={40} className={styles.LogoImage} />,
      onClick: () => {
        setIsUppercase(!isUppercase);
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
      },
      active: isUppercase,
    },
    {
      label: 'Gras',
      style: 'bold',
      method: 'format',
      icon: <Bold width={40} height={40} className={styles.LogoImage} />,
      onClick: () => {
        setIsBold(!isBold);
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
      },
      active: isBold,
    },
    {
      label: 'Italique',
      style: 'italic',
      method: 'format',
      icon: <Italic width={40} height={40} className={styles.LogoImage} />,
      onClick: () => {
        setIsItalic(!isItalic);
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
      },
      active: isItalic,
    },
    {
      label: 'Souligner',
      style: 'underline',
      method: 'format',
      icon: <Underline width={40} height={40} className={styles.LogoImage} />,
      onClick: () => {
        setIsUnderline(!isUnderline);
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
      },
      active: isUnderline,
    },
    {
      label: 'Centrer',
      style: 'CENTER',
      method: 'none',
      icon: <CenterElement />,
      border: true,
    },
    {
      label: 'Liste à puces',
      style: 'unordered-list-item',
      method: 'format',
      icon: (
        <List width={40} height={40} className={classnames(styles.listNumber, styles.LogoImage)} />
      ),
      onClick: () => formatBulletList(),
      active: blockType === 'bullet',
    },
    {
      label: 'Liste numérotée',
      style: 'ordered-list-item',
      method: 'block',
      icon: (
        <ListNumber
          width={40}
          height={40}
          className={classnames(styles.listNumber, styles.LogoImage)}
        />
      ),
      onClick: () => formatNumberedList(),
      active: blockType === 'number',
    },
  ];

  const ActiveIcon = (item: Tool) => {
    return item.active ? styles.iconActive : styles.icon;
  };
  const ClassNameIcon = (item: Tool) => {
    return item.border ? classnames(styles.borderIcon, ActiveIcon(item)) : ActiveIcon(item);
  };
  return (
    <div className={styles.toolbar}>
      {tools.map(item => (
        <Button.Tertiary
          className={ClassNameIcon(item)}
          key={`${item.label}`}
          onClick={item.method !== 'none' ? item.onClick : null}
          onMouseDown={e => e.preventDefault()}
        >
          {item.icon}
        </Button.Tertiary>
      ))}
    </div>
  );
};

export default Toolbar;
