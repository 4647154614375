import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import classNames from 'classnames';
import {
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
  SerializedEditorState,
} from 'lexical';
import React, { useEffect, useState } from 'react';
import { LexicalNews } from 'TextAreaWithFormatting/LexicalNews';

import ToolBar from '../ToolBar';
import Theme from '../ToolBar/Theme';

import styles from './TextAreaWithFormatting.module.scss';
import { Props } from './TextAreaWithFormatting.types';

const editorConfig = {
  namespace: 'MyEditor',
  // The editor theme
  theme: Theme,
  // Handling of errors during update
  onError(error: unknown) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};
const Editor = ({ data, setData, border, textcolor, placeholder }: Props) => {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isLoading) {
      const editorState = activeEditor.parseEditorState(data as SerializedEditorState);
      activeEditor.setEditorState(editorState);
      setIsLoading(false);
    }
  }, [isLoading, activeEditor, data]);
  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        const editorState = editor.getEditorState();
        const json = editorState.toJSON();
        if (data !== json) {
          setData(json as LexicalNews);
        }
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, data, setData]);
  return (
    <div
      className={classNames(styles.editor, border ? styles.border : '')}
      style={{ color: textcolor }}
    >
      <RichTextPlugin
        contentEditable={<ContentEditable />}
        placeholder={<div>{placeholder}</div>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <HistoryPlugin />
      <AutoFocusPlugin />

      <ListPlugin />
      <LinkPlugin />
      <div className={styles.toolbar}>
        <ToolBar />
      </div>
    </div>
  );
};
const TextAreaWithFormatting = (args: Props) => {
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Editor {...args} />
    </LexicalComposer>
  );
};
export default TextAreaWithFormatting;
